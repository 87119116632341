<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" label-suffix="：" :inline="true" size="mini" :model="search">
          <el-form-item label="录入日期">
            <el-date-picker
              v-model="search.create_time_start"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="search.create_time_end"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="姓名/K3码">
            <el-input
              v-model="search.customer_name"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="电话">
            <el-input
              v-model="search.telephone"
              maxlength="30"
              class="w100"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="性别">
            <el-select v-model="search.sex" clearable placeholder class="w80">
              <el-option
                v-for="item in options.sex"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="年龄">
            <el-input
              v-model="search.agemin"
              maxlength="30"
              class="w70"
              clearable
              placeholder="最小"
            />
            <span></span>
            <el-input
              v-model="search.agemax"
              maxlength="30"
              class="w70"
              clearable
              placeholder="最大"
            />
          </el-form-item>
          <el-form-item label="来源">
            <el-select v-model="search.source_id" clearable class="w100" @change="changeSource">
              <el-option
                v-for="item in options.source_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <el-select v-model="search.source_type_id" clearable class="w100" v-if="customerSourceShow" >
              <el-option
                v-for="item in options.source_type_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="客服">
            <el-input
              v-model="search.customer_service_name"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="是否进店">
            <el-select v-model="search.effective_entry" clearable class="w80">
              <el-option
                v-for="item in options.effective_entry"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="会员等级">
            <el-select v-model="search.membership_leve" clearable class="w100">
              <el-option
                v-for="item in options.membership_leve"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="客户标签">
            <el-select v-model="search.member_label" clearable class="w130">
              <el-option
                v-for="item in options.member_label"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="市场" v-if="!userInfo.shop_id">
            <el-select
              v-model="search.market_id"
              clearable
              remote
              filterable
              :remote-method="getMarket"
              placeholder="请输入"
              class="w120"
            >
              <el-option
                v-for="item in options.market_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="门店类型" prop="type">
            <el-select
              v-model="search.type"
              class="w100"
              clearable
              placeholder="门店类型"
            >
              <el-option
                v-for="item in options.type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="门店/门诊" v-if="!userInfo.shop_id">
            <el-select
              v-model="search.shop_id"
              clearable
              remote
              filterable
              :remote-method="remoteMethod"
              placeholder="请输入"
              class="w120"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>

          <!-- v-if="!userInfo.shop_id" -->
          <el-form-item label="员工">
            <el-select
              v-model="search.nurse_id"
              clearable
              remote
              filterable
              :remote-method="remoteMethodStaff"
              placeholder="请输入"
              class="w120"
            >
              <el-option
                v-for="item in options.nurse_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="客户分类">
            <el-select v-model="search.member_classification" clearable class="w100">
              <el-option
                v-for="item in options.member_classification"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item> -->
          <el-form-item label="转店状态">
            <el-select v-model="search.turn_status" clearable class="w100">
              <el-option
                v-for="item in options.turn_status"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="修复进展">
            <el-select v-model="search.repair_progress" clearable class="w100">
              <el-option
                v-for="item in options.repair_progress"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

		  <el-form-item label="疤痕类型">
		    <el-select v-model="search.scar_type_id" clearable class="w100">
		      <el-option
		        v-for="item in options.scar_type_id"
		        :key="item.id"
		        :label="item.name"
		        :value="item.id"
		      />
		    </el-select>
		  </el-form-item>

          <el-form-item label="疤痕明细">
            <el-select v-model="search.scar_id" clearable class="w100">
              <el-option
                v-for="item in options.scar_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="疑难级别">
            <el-select v-model="search.difficulty_level" clearable class="w100">
              <el-option
                v-for="item in options.difficulty_level"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="消费金额">
            <el-input
              v-model="search.consumption_min"
              maxlength="30"
              class="w100"
              clearable
              placeholder="最小金额"
            />
            <span></span>
            <el-input
              v-model="search.consumption_max"
              maxlength="30"
              class="w100"
              clearable
              placeholder="最大金额"
            />
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <div class="btnBox">
        <el-button
          v-if="!userInfo.shop_id"
          type="primary"
          :disabled="!idList.length"
          class="mb10"
          size="mini"
          @click="hint('导出选中列表')"
        >导出数据</el-button>
        <div v-if="userInfo.shop_id"></div>
        <el-button type="primary" round class="mb10" size="mini" @click="tapBtn('客户信息','')">添加客户</el-button>
        <el-button type="info" round class="mb10" size="mini"  @click="xcxshowBalance(2)" v-if="isShowBalance">显示客户余额</el-button>
        <el-button type="info" round class="mb10" size="mini"  @click="xcxshowBalance(1)" v-if="isShowBalance">隐藏客户余额</el-button>
      </div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            v-loading="loading"
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="录入时间" width="150" prop="create_time"  fixed="left"/>
            <ElTableColumn label="姓名" prop="customer_name" width="100"  fixed="left"/>
            <ElTableColumn label="联系电话" prop="telephone" width="100" fixed="left"/>
            <ElTableColumn label="性别" width="50" prop="sex" />
            <ElTableColumn label="年龄" width="50" prop="age" />
            <ElTableColumn label="生日" prop="birthday" width="100" />
            <ElTableColumn label="来源" width="120">
              <template slot-scope="{ row }">
                {{row.source_name}}
                <span v-if="row.source_type_id > 0">--{{row.customer_source_name}}</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="是否进店" width="70" prop="effective_entry" />
            <ElTableColumn label="会员等级" prop="grade_name" />
            <ElTableColumn label="疑难级别(级)" prop="difficulty_level" />
            <ElTableColumn label="护理师" prop="nurse_name" />
            <ElTableColumn label="客服" prop="customer_service_name" />
            <ElTableColumn label="转介绍人" prop="introducer" />
            <ElTableColumn label="门店/门诊" prop="shop_name" width="150" />
            <!-- <ElTableColumn label="客户分类" prop="class_name" /> -->
            <ElTableColumn label="K3码" prop="king_no" />
            <ElTableColumn label="转店状态">
              <template slot-scope="{ row }">
                <span v-if="row.turn_status==0"> </span>
                <span v-if="row.turn_status==1">待处理</span>
                <span v-if="row.turn_status==2">已处理</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="修复进展" prop="progress_name" />
            <ElTableColumn label="疤痕类型" width="170" prop="scar_aggregate" />
            <ElTableColumn label="消费金额" prop="consumption" />
            <ElTableColumn label="消费总金额" prop="sum_money" />
            <ElTableColumn label="操作" fixed="right">
              <template slot-scope="{ row }">
                <span class="theme" @click="tapBtn('客户详情',row)">查 看</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import {
  clientList,
  ssettingList,
  statusSetting,
  getGradeList,
  clientExport,
  getTwoLabel,
  showBalance
} from "@/api/client";
import { getShopList,getMarketLsit } from "@/api/shop";
import { getStaffList } from "@/api/staff";


class Search {
  create_time_start = "";
  create_time_end = "";
  customer_name = "";
  sex = 0;
  agemin = "";
  agemax = "";
  source_id = "";
  source_type_id = "";
  effective_entry = 0;
  customer_service_name = "";
  membership_leve = "";
  shop_id = "";
  nurse_id = "";
  member_classification = "";
  repair_progress = "";
  turn_status = "";
  source_name = "";
  scar_id = "";
  difficulty_level = "";
  consumption_min = "";
  consumption_max = "";
  type = "";
  market_id = "";
}
class Options {
  sex = [
    { id: 0, name: "全部" },
    { id: 1, name: "男" },
    { id: 2, name: "女" }
  ];
  source_id = [];
  source_type_id = [];
  effective_entry = [
    { id: 0, name: "全部" },
    { id: 1, name: "是" },
    { id: 2, name: "否" }
  ];
  membership_leve = [];
  member_label = [];
  shop_id = [];
  market_id = [];
  nurse_id = [];
  member_classification = [];
  repair_progress = [];
  turn_status = [
    { id: 0, name: "全部" },
    { id: 1, name: "待处理" }
  ];
  scar_id = [];
  difficulty_level = [
    { id: 1, name: "1级" },
    { id: 2, name: "2级" },
    { id: 3, name: "3级" },
    { id: 4, name: "4级" }
  ];
  type = [
    {id: "1",name: "直营"},
    {id: "2",name: "加盟"},
    {id: "3",name: "直管"}
  ];

  scar_type_id = [
    {id: "1",name: "疤痕"},
    {id: "2",name: "青春痘"},
    {id: "3",name: "项目"}
  ];
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: "ClientList",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(),
      options: new Options(),
      page: new Page(),
      list: [],
      idList: "",
      loading: false,
      customerSourceShow:false,
      isShowBalance:false,

    };
  },
  filters: {
    statusFilter(val) {
      return ["启用", "禁用"][+val];
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getOptions();
    if (typeof this.$route.query.search == "object") {
      this.search = this.$route.query.search;
      this.page = this.$route.query.page;
    }
    this.getList();
  },
  methods: {
    changeSource(e){
       this.search.source_type_id = "";
      if(e == 665){
          this.customerSourceShow = true;
      }else{
          this.customerSourceShow = false;
      }
    },
    // 获取选项
    getOptions() {
      getGradeList().then(res => {
        this.options.membership_leve = res.list;
      });
      getTwoLabel().then(res => {
        this.options.member_label = res.data;
      });
      ssettingList({ page: 1, limit: 100000 }).then(res => {
        let that = this;
        res.list.forEach(function(item) {
          if (item.category_id == 1) {
            that.options.scar_id.push(item);
          } else if (item.category_id == 4) {
            that.options.source_id.push(item);
          } else if (item.category_id == 5) {
            that.options.repair_progress.push(item);
          } else if (item.category_id == 8) {
            that.options.member_classification.push(item);
          }else if (item.category_id == 15) {
            that.options.source_type_id.push(item);
          }
        });
      });
    },
    // 获取列表
    getList(option) {
      this.loading = true;
      if (option === "search") this.page = new Page();
      clientList({ ...this.search, ...this.page, is_join_shop: 0 }).then(
        res => {
          this.list = res.list;
          this.page.total = res.dataCount;
          this.loading = false;
          this.isShowBalance = res.is_show_balance;
        }
      );
    },
    // 搜索市场
    getMarket(e) {
      getMarketLsit({ page:1, limit:100000, marketName:e }).then(res => {
        console.log(res);
        this.options.market_id = res;
      })
    },

    // 搜索门店
    remoteMethod(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shop_id = res.list;
      });
    },

    // 搜索员工
    remoteMethodStaff(e) {
      getStaffList({ page: 1, limit: 100000, staffName: e }).then(res => {
        this.options.nurse_id = res.list;
      });
    },
    //全部显示客户余额
    xcxshowBalance(type){
      showBalance({type:type}).then(
        res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        });
    },
    // 跳转按钮
    tapBtn(routerName, row) {

      const query = {
        id: row.id,
        activeName: "first",
        customer_name: row.customer_name,
        search: this.search,
        pageName: "客户列表",
        page:this.page
      };
      //this.$router.push({ name: routerName, query });
      if(routerName=='客户信息'){
        this.$router.push({ name: routerName, query });
      }
	   if(routerName=='客户详情'){
      let route = this.$router.resolve({ name: routerName, query });
      window.open(route.href, '_blank');
	   }
    },
    // 提示
    hint(text) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.getExport();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 导出
    getExport() {
      clientExport({ id: this.idList, type: 1 }).then(res => {
        window.open(res.data.url);
      });
    },
    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.mainColor {
  color: #2dc0a3;
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
}

.el-form-item {
  margin: 5px 20px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 0px 10px 80px;
  .btnBox {
    display: flex;
    justify-content: end;
    // justify-content: space-between;
  }
}
</style>
